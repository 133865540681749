<template>
  <div style="position:absolute;top: 2%; left: 1%;z-index: 1;width: 450px">

    <SearchControl
      v-if="!['ItineraryDetails'].includes($route.name)"
    />

    <TopBarButtonBack
      v-else />

    <v-card height="100%" class="mt-6" v-if="toggleMainPanel" style="border-radius: 10px !important; overflow-y: auto !important;">
      <router-view :key="$route.path"/>
    </v-card>

  </div>
</template>

<script>

export default {

  name: "LeftPanelRoot",

  components: {
    TopBarButtonBack: () => import("@/components/Common/Controls/TopBarButtonBack"),
    MainPanel: () => import("@/views/MainPanel"),
    SearchControl: () => import("@/components/Common/Topbar"),
    Account: () => import("@/components/Common/Controls/AccountTopBar")
  },

  computed: {

    toggleMainPanel: {
      get() {
        return this.$store.getters['showLeftPanel'];
      },

      set(val) {
        this.$store.commit("setShowLeftPanel", val);
      }

    }
  }
}
</script>

<style scoped>

</style>